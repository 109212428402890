.projBC{
    background: rgba(0,0,0,0.6);
    margin-top: -25px;
    padding-bottom: 50px;
}
.projCards{
    display: flex;
    justify-content: space-around;
}

.projTitle{
    text-align: center;
    font-size: 60px;
    margin-top: -25px;
}

.projCard {
    transition: .3s;
    background: rgba(0, 0, 0, 0.4);
    width: 25%;
    position: relative;
    text-align: center;
    border-radius: 10px;
}

.projCard:hover {
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.projCont {
    height: 100%;
    width: 101%;
    position: absolute;
    top: 50%;
    left: 50.3%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .3s;
    background: black;
    color: white;
    border-radius: 10px;
}

.projSelect {
    height: 100%;
    width: 101%;
    position: absolute;
    top: 50%;
    left: 50.3%;
    transform: translate(-50%, -50%);
    opacity: .95;
    transition: .3s;
    background: black;
    color: white;
    border-radius: 10px;
    box-shadow: 5px 5px 29px 12px #FFFFFF;
}

.projCard:hover .projCont {
    opacity: .95;
}

.projImg {
    max-width: 100%;
    border-radius: 10px;
    border: solid black 2px;
}

.projName {
    font-size: 30px;
}

.projTeam {
    font-size: 20px;
}

.projPres{
    margin-top: 50px;
    text-align: center;
}
