.app{
  position: relative;
  overflow: scroll;
}

.main {
  height: 105%;
  width: 100%;
  display: block;
  background-image: url(https://i.imgur.com/3zsAWYG.jpg);
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover; 
}

.meMain {
  display: block;
}

.about {
  display: block;
}

.main2{
  display: block;
  background-image: url(https://i.imgur.com/3zsAWYG.jpg);
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover; 
  padding-bottom: 20px;
}
h1{
  color: white;
  font-family: 'Montserrat', sans-serif;
}

a{
  font-family: 'Montserrat', sans-serif;
}

h2{
  color: white;
  font-family: 'Montserrat', sans-serif;
  margin-top: 15px;
}

h3{
  color: white;
  font-size: 25px;
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;
  margin-top: 15px;
}

p{
  font-family: 'Roboto', sans-serif;
  color: white;
}