.meBG {
    margin-top: -25px;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.6);
    display: grid;
    grid-template-rows: 20% 1fr;
}

.meCon {
    grid-row: 2/-1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    min-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.meCover {
    grid-column: 1/2;
    margin-left: auto;
    margin-top: 50px;
}


.meimg {
    max-height: 500px;
    grid-column: 2/-1;
    border-radius: 100px ;
    box-shadow: 10px 14px 19px 3px #000000
    ;
}

.meName {
    font-size: 55px;
    color: white;
}

.meTxt {
    text-align: left;
    font-size: 25px;
    color: white;
}