.nav {
    margin-top: 0;
    margin-bottom: 0;
    background: black;
    width: 100%;
    color: white;
    height: 70px;
    box-shadow: 0px 9px 50px 11px #000000;
    position: fixed;
    top:0; 
}

ul {
    list-style-type: none;
    margin:0 50px 0 50px;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

li {
    display: inline;
    font-size: 25px;
}

.linked:hover {
    cursor: pointer;
}

.linked {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
  }
  
  .linked:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  .linked:hover:after { 
    width: 100%; 
    left: 0; 
  }

  .navImg{
    max-height: 40px;
    margin-top: 30%;
  }

  .navImg2{
    max-height: 46px;
    margin-top: 20%;
  }