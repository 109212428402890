.aboutBG {
    margin-top: -22px;
    background: Black;
    width: 100%;
    color: white;
    text-align: center;
}

.aboutHead{
    font-size: 60px;
    margin-top: 22px;
}

.aboutTxt {
    text-align: center;
    margin: 2px 12%;
    font-size: 25px;
}

.aboutStr{
    margin: 3% 10%;
    font-size: 35px;
}

.skillContainer {
    margin: 0 12%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.skillSingle {
    height: 200px;
    width: 200px;
}

.skillTxt {
    margin-top: -4px;
}

.skillSz {
    max-height: 96px;
}
